document.addEventListener('DOMContentLoaded', () => {
  const $fileInput = document.getElementById('file-input');
  if ($fileInput === null) {
    return;
  }
  const $uploadBar = document.getElementById('upload-progress');
  const $uploadErrorField = document.getElementById('upload-error-field');
  const $uploadInfoField = document.getElementById('upload-info-field');
  const $currentFileField = document.getElementById('upload-current-file-field');

  if ($uploadErrorField !== null && $uploadInfoField !== null) {
    $fileInput.addEventListener('change', () => {
      $uploadBar.style.display = 'none';
      $uploadBar.value = '0';
      $uploadErrorField.style.display = 'none';
      $uploadErrorField.textContent = '';
      $uploadInfoField.textContent = $uploadInfoField.dataset.add + ': ' + $fileInput.files[0].name;
      if ($currentFileField !== null) {
        $currentFileField.parentNode.removeChild($currentFileField);
        $uploadInfoField.classList.add("title-padding");
      }
      $uploadInfoField.style.display = 'flex';
    });
  }

  addEventListener('direct-upload:initialize', (event) => {
    $uploadBar.style.display = 'flex';
  });
  addEventListener('direct-upload:progress', (event) => {
    const {progress} = event.detail;
    $uploadBar.value = progress;
  });
  addEventListener('direct-upload:error', (event) => {
    event.preventDefault();
    const {error} = event.detail;
    $uploadErrorField.textContent = $uploadErrorField.dataset.error + ((typeof error === 'string') ? ': ' + error : '!');
    $uploadErrorField.style.display = 'flex';
    $uploadInfoField.style.display = 'none';
    // Re-enable submit button
    document.querySelector('input[type="submit"]').removeAttribute('disabled');
  });
  addEventListener('direct-upload:end', (event) => {
    $uploadInfoField.textContent = $uploadInfoField.dataset.end + '.';
  });
});
