function updateSelUsers(selUsersList) {
  const selUserArray = [];

  for (let index = 0; index < selUsersList.length; index++) {
    selUserArray.push(selUsersList[index].value);
  }

  const $fileboxField = document.getElementById('file_box_allowed_users');
  const $versionField = document.getElementById('version_allowed_users');
  const $projectTesterField = document.getElementById('project_testers');

  if ($fileboxField !== null) {
    $fileboxField.value = selUserArray.join(' ');
  } else if ($versionField !== null) {
    $versionField.value = selUserArray.join(' ');
  } else if ($projectTesterField !== null) {
    $projectTesterField.value = selUserArray.join(' ');
  }
}

function clickUserUpdateButton(fromList, toList, addAction) {
  return () => {
    const len = fromList.selectedOptions.length;
    for (let index = 0; index < len; index++) {
      toList.add(fromList.selectedOptions[0].cloneNode(true));
      fromList.remove(fromList.selectedOptions[0].index);
    }
    if (addAction) {
      updateSelUsers(toList);
    } else {
      updateSelUsers(fromList);
    }
  };
}

document.addEventListener('DOMContentLoaded', () => {
  const $allUsersList = document.getElementById('alluserslist');
  const $selUsersList = document.getElementById('seluserslist');

  const $addUserBtn = document.getElementById('adduser-btn');
  if ($addUserBtn !== null) {
    $addUserBtn.addEventListener('click', clickUserUpdateButton($allUsersList, $selUsersList, true));
  }

  const $rmUserBtn = document.getElementById('rmuser-btn');
  if ($rmUserBtn !== null) {
    $rmUserBtn.addEventListener('click', clickUserUpdateButton($selUsersList, $allUsersList, false));
  }
});
