document.addEventListener('DOMContentLoaded', () => {

  const $notification_boxes = document.getElementsByClassName("notification-box");

  const $read_more_buttons = document.getElementsByName("read-more-btn");

  if($notification_boxes !== null){
    for(const $box of $notification_boxes) {
      if($box.clientHeight < 200){
        $box.querySelector("div.read-more-fade").parentNode.removeChild($box.querySelector("div.read-more-fade"));
        $box.parentElement.querySelector("button[name='read-more-btn'").parentNode.removeChild($box.parentElement.querySelector("button[name='read-more-btn'"));
      }
    }
  }

  if($read_more_buttons !== null){
    for(const $button of $read_more_buttons) {
      $button.addEventListener('click', function(event) {
        const $fade = $button.parentElement.parentElement.querySelector('div.read-more-fade');
        $fade.parentNode.removeChild($fade);
        const $not_box = $button.parentElement.parentElement.parentElement.querySelector('div.notification-box');
        $not_box.classList.remove("notification-box");
        $button.parentElement.removeChild($button);
      });
    }
  }
});