document.addEventListener('DOMContentLoaded', () => {
  (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
    $notification = $delete.parentNode;
    $delete.addEventListener('click', () => {
      if ($notification !== null && $notification.parentNode !== null) {
        $notification.parentNode.removeChild($notification);
      }
    });
  });
});
