document.addEventListener('DOMContentLoaded', () => {
  const $copyButton = document.getElementById('copy-btn');
  const $shareInput = document.getElementById('share');

  const $shareEmailField = document.getElementById('download-email-field');
  const $shareEmailButton = document.getElementById('download-email-btn');
  const $emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  if ($copyButton !== null && $shareInput !== null) {
    $copyButton.addEventListener('click', () => {
      $shareInput.select();
      document.execCommand('copy');
    });
    $shareInput.addEventListener('click', () => {
      $shareInput.select();
    });
  }

  if ($shareEmailField !== null && $shareEmailButton !== null) {
    $shareEmailButton.disabled = true;

    $shareEmailField.addEventListener('input', () => {
      if ($emailRegex.test($shareEmailField.value)){
        $shareEmailButton.disabled = false;
      }else{
        $shareEmailButton.disabled = true;
      }
    });
  }
});
