document.addEventListener('DOMContentLoaded', () => {

  const $checkbox = document.getElementById("user-password-generate-checkbox");
  const $inputs = document.getElementById("user-password-generate-inputs");

  if ($checkbox !== null && $inputs !== null) {
    $checkbox.checked = true;
    $inputs.style.display = "none";

    $checkbox.addEventListener( 'change', function() {
      if(this.checked) {
        $inputs.style.display = "none";
      } else {
        $inputs.style.display = "block";
      }
    });
  }
});
